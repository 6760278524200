.team {
  padding-right: 100px;
  padding-left: 100px;
  margin-top: 67px;
}

.team__item {
  position: relative;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}

.team__item:hover {
  background-color: #f5f5f5;
}

.team__items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: left;
  -webkit-justify-content: left;
  -ms-flex-pack: left;
  justify-content: left;
}

.team__item__leftColumn {
  float: left;
  margin-right: 30px;
}

.team__item__rightColumn {
  padding-right: 10px;
  padding-right: 10px;
}

.team__item__text {
  max-width: 700px;
  padding-top: 20px;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
}

.team__item__text ul {
  padding-left: 20px;
}

.team__item__text ul li {
  color: #ff7300;
}

.team__item__text ul li span {
  color: black;
}

.team__item__text__bold {
  padding-bottom: 10px;
  font-weight: 600;
}

.team__item__titleCell {
  vertical-align: bottom;
}

.team__item__title {
  font-size: 20px;
}

.team__item__photo img {
  width: 300px;
}

.team__item__photo img:last-child {
  display: none;
}

.team__item:hover .team__item__photo img:last-child {
  display: block;
}

.team__item:hover .team__item__photo img:first-child {
  display: none;
}

.team__item__name {
  font-size: 30px;
  font-weight: 300;
}

.team__item__separator {
  width: 300px;
  border-top: 1px solid black;
}

@media (max-width: 991px) {
  .team {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .team__item__title {
    left: -10px;
  }
  .team__item__photo img {
    width: 200px;
  }
}
@media (max-width: 550px) {
  .team__item {
    display: block;
  }
  .team__item__photo img {
    width: unset;
    min-width: 300px;
    max-width: 500px;
  }
}